import { Analytics } from '@june-so/analytics-node'
import { useAuthStore } from '@/stores/auth'
import { addHours } from 'date-fns'
import { useProjectsStore } from '@/stores/projects'

/**
 * Service for analytics (june)
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
const init = () => {
	const june = new Analytics(import.meta.env.VITE_JUNE_ID)

	const track = (event: string, props: any = undefined, params: any = {}) => {
		try {
			const projectCountry = useProjectsStore().selected?.country

			if (!import.meta.env.DEV) {
				june.track({
					event,
					userId: useAuthStore().user!.id,
					context: {
						groupId: useAuthStore().user?.company,
						page: {
							url: window.location.href,
						},
					},
					properties: {
						projectCountry,
						userCountry: useAuthStore().user?.country,
						...props,
					},
					...params,
				})
			} else {
				console.info('Would track event in production env:', event, props)
			}
		} catch (e) {
			console.warn('error tracking event', e)
		}
	}

	const updateLastSeen = () => {
		localStorage.setItem('last_seen_' + useAuthStore().user?.id, new Date().valueOf().toString())
	}

	const activity = () => {
		if (!useAuthStore().isLoggedIn()) {
			return
		}

		const lastSeenString = localStorage.getItem('last_seen_' + useAuthStore().user?.id)

		if (!lastSeenString) {
			updateLastSeen()
			console.info('Usage session started')
			track('Usage session started')
			return
		}

		const lastSeen = new Date(parseInt(lastSeenString))

		if (lastSeen < addHours(new Date(), -1)) {
			// last seen more than 1h ago
			console.info('User returned to session')
			track('Returned to session')
		}

		updateLastSeen()
	}

	return {
		june,
		track,
		activity,
	}
}

const analytics = init()
export const useAnalytics = () => analytics
