import { createRouter, createWebHistory } from 'vue-router'
import type { NavigationGuard } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useEventBus } from '@/stores/bus'
import { useProjectsStore } from '@/stores/projects'
import { useAnalytics } from '@/services/AnalyticsService'

/**
 * Navigation guard restoring (but not requiring) authentication
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
const restoreAuth: NavigationGuard = async (to, from, next) => {
	const authStore = useAuthStore()
	await authStore.restoreLogin()
	next()
}

/**
 * Navigation guard restoring project selection from URL
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
const restoreProjectSelection: NavigationGuard = async (to, from, next) => {
	const projectStore = useProjectsStore()
	if (to.params.projectId) {
		await projectStore.setProjectById(parseInt(to.params.projectId as string, 10))
	}
	next()
}

/**
 * Navigation guard requiring authenticated user (redirecting to login otherwise)
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
const requireAuth: NavigationGuard = async (to, from, next) => {
	const authStore = useAuthStore()

	if (!authStore.isLoggedIn()) {
		await authStore.restoreLogin()
	}

	if (!authStore.isLoggedIn()) {
		localStorage.setItem('authRedirect', to.fullPath)
		next({ name: 'login' })
	} else {
		next()
	}
}

/**
 * Navigation guard requiring a permission
 *
 * @author Dion Purushotham <mail@dion.codes>
 */
const requirePermission = (permission: string): NavigationGuard => {
	return async (to, from, next) => {
		const authStore = useAuthStore()

		if (!authStore.isLoggedIn()) {
			await authStore.restoreLogin()
		}

		if (!authStore.isLoggedIn()) {
			localStorage.setItem('authRedirect', to.fullPath)
			next({ name: 'login' })
			useEventBus().emit('alert', 'Session expired. Please sign in.', true)
		} else if (!authStore.hasPermission(permission)) {
			useEventBus().emit('alert', 'Permission missing: ' + permission, true)
			next({
				name: 'home',
			})
		} else {
			next()
		}
	}
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL), // + 'v2'),
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/HomeView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'startScreen.title',
			},
		},
		{
			path: '/news',
			name: 'newsOverview',
			component: () => import('@/views/NewsView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'startScreen.title',
			},
		},

		/* Projects */
		{
			path: '/projects/:projectId?',
			name: 'projects',
			component: () => import('@/views/projects/ProjectSelectionView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'projects',
			},
		},
		{
			path: '/archive/:projectId?',
			name: 'projectsArchive',
			component: () => import('@/views/projects/ProjectSelectionView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navArchive',
			},
		},
		{
			path: '/projects/:projectId/assigned-users',
			name: 'assignedUsers',
			component: () => import('@/views/projects/AssignedUsersView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navAssignedUsers',
			},
		},

		/* Order */
		{
			path: '/order/:projectId?',
			name: 'orderOverview',
			component: () => import('@/views/order/OrderOverview.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navOrder',
			},
		},
		{
			path: '/ro-order/:projectId?',
			name: 'legacyOrderOverview',
			component: () => import('@/views/order/LegacyOrderView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navOrder',
			},
		},

		/* Financials */
		{
			path: '/projects/:projectId/financials/invoices',
			name: 'invoices',
			component: () => import('@/views/financials/InvoicesView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.invoices',
				sidebarSection: 'financials',
			},
		},
		{
			path: '/projects/:projectId/financials/balances',
			name: 'balances',
			component: () => import('@/views/financials/BalancesView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navBalances',
				sidebarSection: 'financials',
			},
		},

		/* Materials */
		{
			path: '/projects/:projectId/materials/shippings',
			name: 'shippings',
			component: () => import('@/views/materials/ShippingsView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.deliveryNotes',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/reshippings',
			name: 'reshippings',
			component: () => import('@/views/materials/ReShippingsView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.backdeliveryNotes',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/dispatch-notes',
			name: 'dispatchNotes',
			component: () => import('@/views/materials/DispatchNotesView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'nav.materials.dispatchNotes',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/rental-use',
			name: 'rentalUse',
			component: () => import('@/views/materials/RentalUseView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.rentalUse',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/digital-reshipping',
			name: 'digitalReshipping',
			component: () => import('@/views/materials/DigitalReshippingView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.digitalReshipping',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/requests/rental-buyout',
			name: 'materialsRentalBuyout',
			component: () => import('@/views/materials/RentalBuyoutView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'materials.rentalBuyout.title',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/requests/reorder/:type',
			name: 'materialsReorder',
			component: () => import('@/views/materials/RentalReorderView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'materials.rentalReorder.title',
				sidebarSection: 'materials',
			},
		},
		{
			path: '/projects/:projectId/materials/rental-card',
			name: 'rentalCard',
			component: () => import('@/views/materials/RentalCardView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.rentalCard',
				sidebarSection: 'materials',
			},
		},

		/* Technical Planning */
		{
			path: '/projects/:projectId/technical/drawings',
			name: 'drawings',
			component: () => import('@/views/technical/DrawingsView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navDrawings',
				sidebarSection: 'technicalPlanning',
			},
		},
		{
			path: '/projects/:projectId/technical/parts-lists',
			name: 'partsLists',
			component: () => import('@/views/technical/PartsListsView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.navPartsLists',
				sidebarSection: 'technicalPlanning',
			},
		},

		{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('@/views/projects/DashboardView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.navDashboard',
			},
		},

		/* Downloads & Videos */
		{
			path: '/downloads/documents',
			name: 'documents',
			component: () => import('@/views/downloads/DocumentsView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.technicalDocuments',
				sidebarSection: 'downloads',
			},
		},
		{
			path: '/downloads/pricelists',
			name: 'pricelists',
			component: () => import('@/views/downloads/PricelistsView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.pricelist',
				sidebarSection: 'downloads',
			},
		},
		{
			path: '/downloads/software',
			name: 'software',
			component: () => import('@/views/downloads/SoftwareView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.navBim',
				sidebarSection: 'downloads',
			},
		},
		{
			path: '/videos',
			name: 'videos',
			component: () => import('@/views/downloads/VideosView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.videos',
				sidebarSection: 'downloads',
			},
		},
		{
			path: '/videos/hcad',
			name: 'hcadVideos',
			component: () => import('@/views/downloads/HcadVideosView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.navHcad',
				sidebarSection: 'downloads',
			},
		},

		/* Tickets */
		{
			path: '/tickets/new/:inquiryType',
			name: 'newTicket',
			component: () => import('@/views/tickets/NewTicketView.vue'),
			beforeEnter: requireAuth,
			meta: {
				titleKey: 'legacy.ticketsPageUserTitle',
			},
		},
		{
			path: '/projects/:projectId/tickets/new/:inquiryType',
			name: 'newTicketForProject',
			component: () => import('@/views/tickets/NewTicketView.vue'),
			beforeEnter: [requireAuth, restoreProjectSelection],
			meta: {
				titleKey: 'legacy.ticketsPageUserTitle',
			},
		},

		/* Administration */
		{
			path: '/admin/users',
			name: 'userAdminOverview',
			component: () => import('@/views/administration/UserAdminOverview.vue'),
			beforeEnter: requirePermission('pages.admin.users'),
			meta: {
				titleKey: 'admin.users.headline',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/users/new',
			name: 'createUser',
			component: () => import('@/views/administration/CreateUser.vue'),
			beforeEnter: requirePermission('pages.admin.users'),
			meta: {
				titleKey: 'admin.users.headline',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/users/:userId',
			name: 'userDetails',
			component: () => import('@/views/administration/UserDetails.vue'),
			beforeEnter: requirePermission('pages.admin.users'),
			meta: {
				titleKey: 'admin.users.headline',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/users/:userId/roles/new',
			name: 'createUserRole',
			component: () => import('@/views/administration/CreateUserRole.vue'),
			beforeEnter: requirePermission('pages.admin.users'),
			meta: {
				titleKey: 'admin.users.headline',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/users/:userId/roles/:roleId',
			name: 'userRoleDetails',
			component: () => import('@/views/administration/UserRoleDetails.vue'),
			beforeEnter: requirePermission('pages.admin.users'),
			meta: {
				titleKey: 'admin.users.headline',
				sidebarSection: 'admin',
			},
		},

		{
			path: '/admin/system',
			name: 'systemAdmin',
			component: () => import('@/views/administration/SystemAdminView.vue'),
			beforeEnter: requirePermission('pages.admin.preferences'),
			meta: {
				titleKey: 'nav.admin.system',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/news',
			name: 'newsAdmin',
			component: () => import('@/views/administration/NewsAdminView.vue'),
			beforeEnter: requirePermission('pages.admin.news'),
			meta: {
				titleKey: 'nav.admin.news',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/tickets',
			name: 'ticketsAdmin',
			component: () => import('@/views/administration/TicketsAdminView.vue'),
			beforeEnter: requirePermission('pages.admin.tickets'),
			meta: {
				titleKey: 'nav.admin.tickets',
				sidebarSection: 'admin',
			},
		},
		{
			path: '/admin/report',
			name: 'reportAdmin',
			component: () => import('@/views/administration/ReportAdminView.vue'),
			beforeEnter: requirePermission('pages.admin.report'),
			meta: {
				titleKey: 'nav.admin.report',
				sidebarSection: 'admin',
			},
		},

		/* Auth */
		{
			path: '/auth/login',
			name: 'login',
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('@/views/auth/LoginView.vue'),
			beforeEnter: restoreAuth,
			meta: {
				titleKey: 'auth.login.title',
				hideSidebar: true,
			},
		},
		{
			path: '/auth/profile',
			name: 'profile',
			component: () => import('@/views/auth/ProfileView.vue'),
			beforeEnter: restoreAuth,
			meta: {
				titleKey: 'auth.profile.title',
			},
		},
		{
			path: '/auth/sessions',
			name: 'sessions',
			component: () => import('@/views/auth/SessionsView.vue'),
			beforeEnter: restoreAuth,
			meta: {
				titleKey: 'auth.sessions.title',
			},
		},
		{
			path: '/sign-up',
			name: 'signup',
			component: () => import('@/views/auth/SignUpView.vue'),
			meta: {
				titleKey: 'auth.signUp.headline',
				hideSidebar: true,
			},
		},
		{
			path: '/auth/logout',
			name: 'logout',
			component: () => import('@/views/auth/LogoutView.vue'),
			beforeEnter: restoreAuth,
			meta: {
				titleKey: 'auth.signOut',
				hideSidebar: true,
			},
		},
		{
			path: '/auth/forgot',
			name: 'passwordForgot',
			component: () => import('@/views/auth/PasswordForgotView.vue'),
			meta: {
				titleKey: 'auth.passwordReset.title',
				hideSidebar: true,
			},
		},
		{
			path: '/auth/reset-password',
			name: 'passwordReset',
			component: () => import('@/views/auth/PasswordResetView.vue'),
			meta: {
				titleKey: 'auth.passwordReset.title',
				hideSidebar: true,
			},
		},
		{
			path: '/auth/app-redirect',
			name: 'appAuth',
			component: () => import('@/views/auth/AppAuth.vue'),
			meta: {
				titleKey: 'auth.login.title',
				hideSidebar: true,
			},
		},

		/* static pages */
		{
			path: '/imprint',
			name: 'imprint',
			component: () => import('@/views/static/ImprintView.vue'),
			meta: {
				titleKey: 'legacy.imprint',
				hideSidebar: true,
			},
		},
		{
			path: '/privacy',
			name: 'privacyPolicy',
			component: () => import('@/views/static/PrivacyView.vue'),
			meta: {
				titleKey: 'legacy.privacy',
				hideSidebar: true,
			},
		},
		{
			path: '/tos',
			name: 'termsOfService',
			component: () => import('@/views/static/TermsOfServiceView.vue'),
			meta: {
				titleKey: 'legacy.termsofuse',
				hideSidebar: true,
			},
		},

		{
			path: '/:catchAll(.*)',
			name: 'notFound',
			component: () => import ('@/views/NotFound.vue'),
			meta: {
				titleKey: 'errors.404',
				hideSidebar: true,
			},
		},
	],
	scrollBehavior: (to, from, savedPosition) => {
		if (to.name === from.name) {
			return
		}
		return savedPosition ? savedPosition : { top: 0 }
	},
})

/* router.onError((error, to) => {
	if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
		console.info('App update detected')

		if (confirm('A new update is available. Do you want to reload the page? Please only proceed if you don\'t have any unsaved inputs.')) {
			window.location.href = `/v2${to.fullPath.toString()}`
		}
	}
}) */

router.afterEach((to, from, next) => {
	if (!useAuthStore().user) {
		return
	}

	if (!import.meta.env.DEV) {
		useAnalytics().june.page({
			name: to.name?.toString() || to.path.toString(),
			userId: useAuthStore().user!.id,
		})
	}
})

export default router
