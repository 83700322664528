<template>
	<div class="custom-modal-wrapper" ref="modalWrapperElement">
		<transition name="fade">
			<div v-if="modal.show" class="custom-modal" ref="modalElement" :style="{ 'max-width': `${maxWidth}px` }">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { onMounted } from 'vue'
import { nextTick } from 'vue'
import { onBeforeUnmount } from 'vue'

const modalWrapperElement = ref<HTMLDivElement>()
const modalElement = ref<HTMLDivElement>()

defineProps({
	maxWidth: {
		type: Number,
		default: 450,
		required: false,
	},
})

const modal = reactive({
	show: false,
	danger: false,
})

onMounted(() => {
	nextTick(() => {
		modal.show = true
	})
})

onBeforeUnmount(()=> {
	modal.show = false
})
</script>
